<template comments>
  <div>
    <!--noindex-->
    <SpriteSheet />
    <header>
      <ScreenReaderJumpTo text="Skip to main content" to-element="#main" />
      <MegaMenu
        :items="menuData.menu"
        :top-menu="menuData.topNav"
        faculty-name="Study"
        faculty-width="5%"
        faculty-link="/"
        active="https://study.unimelb.edu.au/find"
      />
      <BreadCrumbsBar v-if="sub" />
    </header>
    <!--endnoindex-->
    <nuxt />
    <!--noindex-->
    <PageFooterAlt />
    <!--endnoindex-->
  </div>
</template>

<script>
import {
  PageFooterAlt,
  MegaMenu,
  ScreenReaderJumpTo,
} from '@unimelb/pattern-lib-vue';
import BreadCrumbsBar from '~/components/BreadcrumbsBar.vue';
import SpriteSheet from '~/components/SpriteSheet.vue';
import menuData from '~/static/data/menu.json';
import { analyticsSetPage, analyticsTriggerEvent } from '@/helpers/client';

export default {
  components: {
    MegaMenu,
    BreadCrumbsBar,
    PageFooterAlt,
    SpriteSheet,
    ScreenReaderJumpTo,
  },
  data() {
    return {
      menuData,
      watchAnalytics: false,
    };
  },
  head() {
    return {
      title: this.title,
      meta: [
        { name: 'page_build_time_local', content: new Date().toLocaleString() },
      ],
      link: [
        {
          rel: 'canonical',
          href: `${process.env.PROXY_URL}${this.$route.path}${
            this.$route.path.endsWith('/') ? '' : '/'
          }`,
        },
      ],
    };
  },
  computed: {
    sub() {
      return this.$route.path !== '/find/' || this.$route.path !== '/';
    },
    userProfile() {
      return this.$store.getters.getProfile;
    },
  },
  watch: {
    userProfile(newValue) {
      if (this.watchAnalytics) {
        analyticsTriggerEvent('user_profile_updated', {
          event_name: 'user_profile_updated',
          residency: newValue.residency,
          qualification: newValue.qualificationCode,
          year: newValue.year,
          user_persona: 'prospect',
        });
      }
    },
  },
  mounted() {
    window.utag_data = window.utag_data || {};
    window.utag_data.uom_version = 'v3.0.0';

    window.uom_analytics = window.uom_analytics || {};

    analyticsSetPage({
      page_type: 'Find a Course',
      group: 'Study',
      url: `${process.env.PROXY_URL}${this.$route.path}${
        this.$route.path.endsWith('/') ? '' : '/'
      }`,
    });

    window.addEventListener('load', () => {
      this.watchAnalytics = true;
    });

    if (window.uom_dashboard) {
      window.uom_dashboard.fn.init();
    }

    // for testing on preview... its a SPA it doesn't work with window.onload or $nextTick
    //    setTimeout(() => {
    //      this.watchAnalytics = true;
    // eslint-disable-next-line no-magic-numbers
    //    }, 2000);
  },
};
</script>
